import React, { useState } from "react";
import { Col, Divider, Row, Typography, Button, Form, Input } from "antd";

import HeaderComponent from "../../components/Header";

import { Helmet } from "react-helmet";

import FooterComponent from "../../components/Footer";

import favicon from "../../images/favicon.ico";
import Wrapper from "../../components/Wrapper";
import bannerImage from "../../images/banner-no-text.jpg";
import TextArea from "antd/lib/input/TextArea";

import "./contact-us.scss";

const ContactUsPage = () => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prabu Cahaya Abadi | Contact Us</title>
        <meta name="description" content="Kontak PT. Prabu Cahaya Abadi" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <HeaderComponent />
      <div>
        <img
          src={bannerImage}
          alt="banner"
          style={{ maxHeight: 200, width: "100%", objectFit: "cover" }}
        />
      </div>
      <Wrapper>
        <div className="contact-us">
          <Typography className="contact-us__title">Contact Us</Typography>
          <Divider className="contact-us__divider" />
          <div className="contact-us__content">
            <Row justify="space-between" gutter={[24, 24]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography className="contact-us__content__title">
                  Get In Touch
                </Typography>
                <div>
                  <Typography className="contact-us__content__subtitle">
                    Office Address
                  </Typography>
                  <Typography style={{ fontSize: "16px" }}>
                    MTH SQUARE BUILDING LT. 3A, NO.10, <br /> JL. MT Haryono
                    Kav. 10 Jatinegara, <br />
                    Jakarta 13330
                  </Typography>
                  <Typography style={{ fontSize: "16px" }}>
                    Phone: +62-21-2982-7424
                  </Typography>
                  <Typography style={{ fontSize: "16px" }}>
                    Fax: +62-21-2148-1762
                  </Typography>
                </div>
                <div>
                  <Typography className="contact-us__content__subtitle">
                    Rido Riyandi
                  </Typography>
                  <Typography style={{ fontSize: "16px" }}>
                    rido.riyandi@prabusinar9.com
                  </Typography>
                  <Typography style={{ fontSize: "16px" }}>
                    +62-812-1031-1656
                  </Typography>
                </div>
                <div>
                  <Typography className="contact-us__content__subtitle">
                    Hafiz Maulana
                  </Typography>
                  <Typography style={{ fontSize: "16px" }}>
                    hafiz.maulana@prabusinar9.com
                  </Typography>
                  <Typography style={{ fontSize: "16px" }}>
                    +62-812-9089-5424
                  </Typography>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="contact-us__content__form-wrapper">
                  <Typography className="contact-us__content__title">
                    Send us a Message!
                  </Typography>
                  <Form
                    layout="vertical"
                    className="contact-us__content__form-wrapper__form"
                    name="form"
                  >
                    <Form.Item label="Subject">
                      <Input onChange={(e) => setSubject(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Body">
                      <TextArea onChange={(e) => setBody(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        block
                        disabled={subject === "" || body === "" ? true : false}
                        href={`mailto:prabucahayaabadi@gmail.com?subject=${subject}&body=${body}`}
                        className="contact-us__content__form-wrapper__form__submit"
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Wrapper>
      <FooterComponent />
    </div>
  );
};

export default ContactUsPage;
